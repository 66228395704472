import Dexie from "dexie";

const db = new Dexie("cwpos");
db.version(1)
  .stores({
    tillRegistrations: "++id",
    productCategories: "mProductCategoryId",
    products: "++id, mProductId, mProductCategoryId, value, name, upc, *batchIndex, *upcIndex",
    orders: "sOrderID, orderTime, documentno, isSynced, tillSessionId, customerSearchKey",
    pricingRules: "++id, billAmount, mPricingrulesId",
    currency: "++id, csCurrencyId, isoCode",
    posSaletypes: "++id, cwrSaletypeId",
    productUom: "++id, csUomId",
    tillEvents: "++id, tillSessionId, isSynced",
    logConfiguration: "++id",
    logInformation: "++id",
    restaurantTables:"++id",
    tableData:"cwrFbTableId, name, tableSync",
    fbOrderData:"++id,fbOrderId,cwrFbTableId,fbOrderStatus,fbOrderSync",
    sectionTables:"++id",
    AllProductCategories:"mProductCategoryId"
  })
  .upgrade((tx) => {
    // Database Migrations
  });

export default db;
