import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, Input, Button } from "antd";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../constants/config";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { tillClosingPrint } from "../../lib/printer/tillCloseReceipt";
import { Scrollbars } from "react-custom-scrollbars";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";

const CloseTillAll = () => {
  const dynamicHeight = 0.5625;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const [allExpectedAmount, setAllExpectedAmount] = useState(0);
  const [allTransactionCount, setAllTransactionCount] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));

  const paymentMethodList = tillData.tillAccess.csBunit.paymentMethodList;
  const sessionId = tillSession.tillSessionId;
  const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
  const [cashSaleAmount, setCashSaleAmount] = useState(0);
  let currencyPrecision = tillData.tillAccess.csBunit.currencies[0].stdPrecision;

  useEffect(() => {
    if (posConfig.showDenominations === "N") {
      const newPaymentsList = [];
      db.orders
        .where("tillSessionId")
        .equals(sessionId)
        .toArray()
        .then((orders) => {
          paymentMethodList.forEach((payment) => {
            let currPaymentAmount = 0;
            let transactionCount = 0;
            let change = 0;
            orders.forEach((order) => {
              const orderPayments = order.payments;
              change += order.change;
              orderPayments.forEach((orderPayment) => {
                if (payment.name.toLowerCase() === orderPayment.name.toLowerCase()) {
                  currPaymentAmount += parseFloat(orderPayment.amount);
                  transactionCount += 1;
                }
              });
            });
            payment.expectedAmount = currPaymentAmount;
            payment.amount = currPaymentAmount;
            payment.difference = payment.expectedAmount - currPaymentAmount;
            payment.transactionCount = transactionCount;
            payment.change = change;
            newPaymentsList.push(payment);
          });
          const cashIndex = newPaymentsList.findIndex((p) => p.name.toLowerCase() === "cash");
          const cashAmount = parseFloat(newPaymentsList[cashIndex].expectedAmount);
          const openAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount;
          setTotalOpeningAmount(openAmount);
          setTotalOrdersCount(orders.length);
          setCashSaleAmount(cashAmount);
          const updatedTillSession = tillSession;
          const updatedAllPaymentsData = newPaymentsList;
          const aPi = updatedAllPaymentsData?.findIndex((apd) => apd.name.toLowerCase() === "cash");
          updatedAllPaymentsData[aPi].expectedAmount =
            tillData.tillAccess.csBunit.incDayOpening === "N"
              ? parseFloat(updatedAllPaymentsData[aPi].expectedAmount) + parseFloat(tillSession.openingCash) - Math.abs(updatedAllPaymentsData[aPi].change)
              : parseFloat(updatedAllPaymentsData[aPi].expectedAmount) + parseFloat(tillSession.openingCash) + parseFloat(totalOpeningAmount) -Math.abs(updatedAllPaymentsData[aPi].change);
          updatedAllPaymentsData[aPi].amount = totalAmount;
          updatedAllPaymentsData[aPi].expectedAmount = parseFloat(updatedAllPaymentsData[aPi].expectedAmount.toFixed(currencyPrecision) )
          updatedAllPaymentsData[aPi].cashSaleAmount = cashSaleAmount;
          updatedAllPaymentsData[aPi].difference = parseFloat(updatedAllPaymentsData[aPi].amount) - parseFloat(updatedAllPaymentsData[aPi].expectedAmount);
          updatedAllPaymentsData[aPi].difference = parseFloat(updatedAllPaymentsData[aPi].difference.toFixed(currencyPrecision)); 
          updatedTillSession.closingTime = timeStamp();
          updatedTillSession.closingCash = totalAmount;
          updatedTillSession.closingCashDenominations = [];
          updatedTillSession.allPaymentsData = updatedAllPaymentsData;
          updatedTillSession.totalOrdersCount = orders.length;
          localStorage.setItem("tillSession", JSON.stringify(updatedTillSession));
          setAllExpectedAmount(updatedAllPaymentsData[aPi].expectedAmount);
          setAllTransactionCount(updatedAllPaymentsData[aPi].transactionCount);
          setTotalAmount(updatedAllPaymentsData[aPi].amount);
          setTotalOrdersCount(orders.length);
          setPaymentsList([...newPaymentsList]);
        });
    } else if (posConfig.showDenominations === "Y") {
      const tillSessionPayments = tillSession.allPaymentsData;
      let totalAmountAllPayments = 0;
      let transactionCount = 0;
      let total = 0;
      tillSessionPayments?.map((pay) => {
        totalAmountAllPayments += pay.expectedAmount;
        transactionCount += pay.transactionCount;
        total += parseFloat(pay.amount);
        return null;
      });
      setAllExpectedAmount(totalAmountAllPayments);
      setAllTransactionCount(transactionCount);
      setTotalAmount(total);
      setTotalOrdersCount(tillSession.totalOrdersCount);
      setPaymentsList([...tillSessionPayments]);
    }
  }, []);

  const history = useHistory();
  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const excuteCloseTillAll = () => {
    confirmBtnRef.current.disabled = true;
    cancelBtnRef.current.disabled = true;
    const updatedPaymentsList = paymentsList;
    const closingSession = tillSession;
    closingSession.tillStatus = "closed";
    closingSession.totalClosingExpectedAmount = allExpectedAmount;
    closingSession.totalClosingTransactions = allTransactionCount;
    closingSession.totalClosingAmount = totalAmount;
    closingSession.allPaymentsData = updatedPaymentsList;
    closingSession.isSynced = 0;

    db.tillEvents.add(closingSession).then(() => {
      tillClosingPrint(closingSession);
      localStorage.removeItem("tillSession");
      confirmBtnRef.current.disabled = false;
      cancelBtnRef.current.disabled = false;
      history.push("/dashboard");
    });

    if (
      db.logConfiguration.toArray().then((fetched) => {
        fetched.map((item) => {
          item.dayClosing.filter((item) => item.log === "Y").length > 0;
        });
      })
    ) {
      const timeMark = timeStamp();
      const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
      const dayClosingArray = [];
      dayClosingArray.push(`{
        type: "DCL",
        action: "LOG",
        description: "${timeMark}",
        date: "${currentDate}",
        time: "${timeMark}",
        orderNo: "",
        remarks: "",
      }`);
      Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
          upsertPOSLog(order: {
              tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
              userId: "${tillData.tillAccess.csUserId}" 
              bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
              lines: [${dayClosingArray}]
            }) {
            status   
            message
          }
        }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      });
    }
  };

  const onFieldInput = (e) => {
    const { name, value } = e.target;
    const updatedPaymentsList = paymentsList;
    const paymentIndex = updatedPaymentsList.findIndex((pi) => pi.name === name);
    updatedPaymentsList[paymentIndex].amount = value;
    const diff = parseFloat(value) - parseFloat(updatedPaymentsList[paymentIndex].expectedAmount);
    updatedPaymentsList[paymentIndex].difference = isNaN(diff) ? 0 : diff;
    setPaymentsList([...updatedPaymentsList]);
    let totalAmountVal = 0;
    updatedPaymentsList.map((p) => {
      return (totalAmountVal += isNaN(parseFloat(p.amount)) ? 0 : parseFloat(p.amount));
    });
    setTotalAmount(totalAmountVal);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const { name } = e.target;
      const value = 0;
      const updatedPaymentsList = paymentsList;
      const paymentIndex = updatedPaymentsList.findIndex((pi) => pi.name === name);
      updatedPaymentsList[paymentIndex].amount = value;
      const diff = parseFloat(value) - parseFloat(updatedPaymentsList[paymentIndex].expectedAmount);
      updatedPaymentsList[paymentIndex].difference = isNaN(diff) ? 0 : diff;
      setPaymentsList([...updatedPaymentsList]);
      let totalAmountVal = 0;
      updatedPaymentsList.map((p) => {
        return (totalAmountVal += isNaN(parseFloat(p.amount)) ? 0 : parseFloat(p.amount));
      });
      setTotalAmount(totalAmountVal);
    }
  };

  return (
    <div style={data.openTill.mainContainer}>
      <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
        <Col span={2}>
          <img src={MenuIcon} style={data.openTill.header.menuIcon} alt="" />
        </Col>
        <Col span={10}>
          <label style={data.openTill.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
        </Col>
        <Col span={8}>
          <label style={data.openTill.header.timeFmt}>CLOSE TILL</label>
        </Col>
        <Col span={4} style={data.openTill.header.right}>
          <img src={POSLogo} style={data.openTill.header.posLogoIcon} alt="" />
        </Col>
      </Row>
      <div style={data.openTill.mainCardContainer}>
        <Row gutter={10}>
          <Col span={18}>
            <Card bodyStyle={{ padding: 10 }} style={{ borderRadius: "3px" }}>
              <Card key="z" style={data.openTill.mainCardFirst} bodyStyle={{ padding: 0 }}>
                <Row style={{ background: "#EBEDED", padding: "1% 0%" }}>
                  <Col span={5} style={data.openTill.colAlign}>
                    <strong>Payment Name</strong>
                  </Col>
                  <Col span={4} style={data.openTill.colAlign}>
                    <strong>Transactions</strong>
                  </Col>
                  <Col span={5} style={data.openTill.alignCenter}>
                    <strong>Expected Amount</strong>
                  </Col>
                  <Col span={5} style={data.openTill.alignCenter}>
                    <strong>Actual Amount</strong>
                  </Col>
                  <Col span={5} style={data.openTill.colAlign}>
                    <strong>Difference</strong>
                  </Col>
                </Row>
                <Row style={{ padding: 10 }}>
                  <Scrollbars autoHide className="closeTillScroll">
                    {paymentsList.map((payment, index) => (
                      <Row style={{ marginBottom: "2%", marginTop: "1%" }}>
                        <Col span={5} style={data.openTill.paymentColAlign}>
                          <h6 style={data.openTill.paymentDetails}>{payment.name}</h6>
                        </Col>
                        <Col span={4} style={data.openTill.paymentColAlign}>
                          <h6 style={data.openTill.paymentDetails}>{payment.transactionCount}</h6>
                        </Col>
                        <Col span={5} style={data.openTill.paymentColAlign}>
                          <h6 style={data.openTill.paymentDetails}>{parseFloat(payment.expectedAmount).toFixed(2)}</h6>
                        </Col>
                        <Col span={5} style={data.openTill.colAlign}>
                          <Input
                            name={`${payment.name}`}
                            value={payment.amount}
                            onChange={onFieldInput}
                            onBlur={onFieldOut}
                            readOnly={payment.name.toLowerCase() === "cash" && posConfig.showDenominations === "Y" ? true : false}
                            style={payment.name.toLowerCase() === "cash" && posConfig.showDenominations === "Y" ? data.openTill.currency : data.openTill.actualAmtInput}
                          />
                        </Col>
                        <Col span={5} style={data.openTill.paymentColAlign}>
                          <h6 style={data.openTill.paymentDetails}>{parseFloat(payment.difference).toFixed(2)}</h6>
                        </Col>
                      </Row>
                    ))}
                  </Scrollbars>
                </Row>
              </Card>
            </Card>
          </Col>
          <Col span={6}>
            <Card bodyStyle={{ padding: 10 }} style={data.openTill.opentillRightCard}>
              <Link to={posConfig.showDenominations === "Y" ? "/close-till" : "/dashboard"}>
                <Button ref={cancelBtnRef} style={data.openTill.btnCancel}>
                  Back
                </Button>
              </Link>
              <Button ref={confirmBtnRef} style={data.openTill.btnConfirm} onClick={excuteCloseTillAll}>
                Close Till
              </Button>
              <p style={data.openTill.emptyP} />
              <p style={data.openTill.emptyP}>Opened On</p>
              <div style={{ marginBottom: "0.5rem" }}>
                <span style={data.openTill.currentDate}>{currentDate}</span>
                <span style={data.openTill.displayClock}>{displayClock}</span>
              </div>
              <Form>
                <Form.Item style={{ marginBottom: "0.5rem" }}>
                  <p style={data.openTill.transactions}>Transactions</p>
                </Form.Item>
                <Form.Item style={{ marginBottom: "1rem" }}>
                  <p style={data.openTill.transactionsAmt}>Payment Transactions</p>
                  <Input type="number" value={allTransactionCount} readOnly={true} className="transactionAmtInput" />
                </Form.Item>
                <Form.Item style={{ marginBottom: "1rem" }}>
                  <p style={data.openTill.transactionsAmt}>Retail Transactions</p> <Input type="number" value={totalOrdersCount} readOnly={true} className="transactionAmtInput" />
                </Form.Item>
                <Form.Item style={{ marginBottom: "1rem" }}>
                  <p style={data.openTill.transactionsAmt}>Expected Amount</p>
                  <Input type="number" value={`${parseFloat(allExpectedAmount).toFixed(2)}`} readOnly={true} className="transactionAmtInput" />
                </Form.Item>
                <Form.Item style={{ marginBottom: "1rem" }}>
                  <p style={data.openTill.transactionsAmt}>Total Amount</p>
                  <Input value={`${parseFloat(totalAmount).toFixed(2)}`} readOnly={true} className="transactionAmtInput" />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CloseTillAll;
