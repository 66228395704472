import axios from "axios";
import { currencyTypes } from "../../constants/taxCurrencies";

export const xmlPrint = (printReciptData, receiptType) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  let printData = tillData.tillAccess.cwrTill.printTemplate.xmlcode2;
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;

  /* let printData = `
   <?xml version="1.0" encoding="UTF-8"?>
   <printer>
   	<pos-order>
   		<line>
   			<image>https://cw.solutions/wp-content/uploads/2020/02/CW_LOGO_90.jpg</image>
   		</line>
   		<line> 
   			<text align="center" length="42">POS Invoice</text>
   		</line>
   		<line>
   			<text align="center">JOY General Stores</text>
   		</line>
   		<line>
   			<text align="center">@@csBunitCustomerAddressFulladdress@@</text>
   		</line> 
   		<line>
   			<text align="center">GST: 36AAHCD4767B1ZP </text>
   		</line> 
   		<line>
   			<text align="center"> Phone: @@csBunitCustomerAddressPhone@@ </text>
   		</line> 
   		<line>
   			<text>---------------------------------------------</text>
   		</line>
   		<line>
   			<text align="center">@@receiptType@@</text>
   		</line> 
   		<line>
   			<text align="left" length='40'>Customer: @@printReciptDataCustomerName@@</text>
   			<text align="right" length='40'>Cashier: @@username@@</text>
   		</line> 
   		<line>
   			<text align="left" length='40'>@@printReciptDataCustomerMobileNo@@</text>
   		</line>
   		<line>
   			<text align="left" length='40'>Document: @@printReciptDataDocumentno@@</text>
   			<text align="right" length='40'>Date: @@date@@</text>
   		</line>
   		<line>
   			<text align="left" length='40'>NAME</text>
   			<text align="left" length='20'>Qty</text>
   			<text align="left" length='20'>Price</text>
   		</line>
   		<line>
                   	<text align="left" length='20'>HSN</text>
                   	<text align="left" length='20'>CGST</text>
                   	<text align="left" length='20'>SGST</text>
                   	<text align="left" length='20'>AMOUNT</text>
                   </line>
   		@@printReciptDataItems@@
   		<line>
   			<text>----------------------------------------------------</text>
   		</line>
   		<line>
   			<text align="left" length='40'>Total Items: </text>
   			<text align="left" length='40'>Total Quantity: </text>
   		</line>
   		<line>
   			<text align="left" length='40'>@@printReciptDataItemsLength@@</text>
   			<text align="left" length='40'>@@printReciptDataTotalQty@@</text>
   		</line>
   		@@taxAmountBreakup@@
   		<line></line>
   		<line>
   			<text align="left" length='40'>Net Amount:</text>
   			<text align="right" length='40'>@@netAmount@@</text>
   		</line>
   		<line>
   			<text align="left" length='40'>Tax Amount:</text>
   			<text align="right" length='40'>@@taxAmount@@</text>
   		</line>
   		<line>
   			<text align="left" length='40'>Discount:</text>
   			<text align="right" length='40'>@@discountAmount@@</text>
   		</line>
   		<line>
   			<text align="left" length='40'>Round Off:</text>
   			<text align="right" length='40'>@@roundOff@@</text>
   		</line>
   		<line>
   			<text align="left" length='40'>Total</text>
   			<text align="right" length='40'>@@total@@</text>
   		</line> 
   		@@printReciptDataPayments@@
   		<line>
   			<text align="left" length='40'>Change</text>
   			<text align="right" length='40'>@@cashChangeAmount@@</text>
   		</line>
   		<line>
   			<text align="left" length='40'>Amount Received: </text>
   			<text align="right" length='40'>@@amountReceived@@</text>
   		</line>
   		<line></line>
   		<line>
   			<text align="centre">Thank you for shopping with us. For free home delivery pls call 9153048888</text>
   		</line>
   		<line>
   			<barcode type="CODE128" position="left">@@printReciptDataDocumentno@@</barcode>
   		</line>
   	</pos-order>
   </printer>`; */

  const addedToCart = printReciptData.items;
  const taxKeyValue = [];
  const taxType = [];

  for (let i = 0; i < addedToCart.length; i += 1) {
    let taxRate = addedToCart[i].taxRate;
    if (taxType.indexOf(taxRate) !== -1) {
      let taxVal = currenciesList[0].isoCode === "INR" ? addedToCart[i].taxAmount / 2 : addedToCart[i].taxAmount;
      for (let j = 0; j < taxKeyValue.length; j += 1) {
        const keys = Object.keys(taxKeyValue[j]);
        if (keys[0].toString() === taxRate.toString()) {
          taxKeyValue[j][taxRate] = taxKeyValue[j][taxRate] + taxVal;
        }
      }
    } else {
      taxType.push(taxRate);
      let taxVal = currenciesList[0].isoCode === "INR" ? addedToCart[i].taxAmount / 2 : addedToCart[i].taxAmount;
      taxKeyValue.push({ [taxRate]: taxVal });
    }
  }

  let taxTypeCurrencyWise = currencyTypes.filter((item, index) => item.type === currenciesList[0].isoCode);

  let taxAmountBreakup = "";
  taxKeyValue.map((value, i) =>
    taxTypeCurrencyWise[0].taxType?.map(
      (item) =>
        (taxAmountBreakup += `<line>
       <text align="left" length='40'>${item} ${item === "CGST" || item === "SGST" ? taxType[i] / 2 : taxType[i]}%</text>
       <text align="right" length='40'>${value[taxType[i]].toFixed(2)}</text>
     </line>`)
    )
  );
  //   taxKeyValue.map((value, i) => {
  //     return (taxAmountBreakup += `<line>
  //         <text align="left" length='40'>CGST ${taxType[i] / 2}%</text>
  //         <text align="right" length='40'>${value[taxType[i]].toFixed(2)}</text>
  //       </line>
  //       <line>
  //         <text align="left" length='40'>SGST ${taxType[i] / 2}%</text>
  //         <text align="right" length='40'>${value[taxType[i]].toFixed(2)}</text>
  //       </line>`);
  //   });

  let printItems = "";
  printReciptData.items.map((data) => {
    return (printItems += `<line>
    <text align="left" length='40'>${data.shortDescription === null ? data.name : data.shortDescription}</text>
    <text align="left" length='20'>${parseFloat(data.weight).toFixed(2)}</text>
    <text align="left" length='20'>${parseFloat(data.salePrice).toFixed(2)}</text>
     </line>
     <line>
     <text align="left" length='20'>${data.hsncode === null ? "-" : data.hsncode}</text>
     ${taxTypeCurrencyWise[0].taxType?.map((item) =>
       item === "CGST" || item === "SGST"
         ? `<text align="left" length='20'>${parseFloat(parseFloat(data.taxAmount) / 2).toFixed(2)}</text>`
         : `<text align="left" length='20'>${parseFloat(parseFloat(data.taxAmount)).toFixed(2)}</text>`
     )}
     <text align="left" length='20'>${parseFloat(data.nettotal).toFixed(2)}</text>
     </line>
     `);
  });

  // printReciptData.items.map((data) => {
  //   return (printItems += `<line>
  //   <text align="left" length='40'>${data.shortDescription === null ? data.name : data.shortDescription}</text>
  //   <text align="left" length='20'>${parseFloat(data.weight).toFixed(2)}</text>
  //   <text align="left" length='20'>${parseFloat(data.salePrice).toFixed(2)}</text>
  //    </line>
  //    <line>
  //    <text align="left" length='20'>${data.hsncode === null ? "-" : data.hsncode}</text>
  //    <text align="left" length='20'>${parseFloat(parseFloat(data.taxAmount) / 2).toFixed(2)}</text>
  //    <text align="left" length='20'>${parseFloat(parseFloat(data.taxAmount) / 2).toFixed(2)}</text>
  //    <text align="left" length='20'>${parseFloat(data.nettotal).toFixed(2)}</text>
  //    </line>
  //    `);
  // });

  let printPayments = "";
  printReciptData.payments.map((data) => {
    return (printPayments += `<line>
        <text align="left" length='40'>${data.name}</text>
         <text align="right" length='40'>${parseFloat(data.amount).toFixed(2)}</text>
      </line>`);
  });

  const customerName = printReciptData.customer.name === "Anonymous Customer" ? "" : printReciptData.customer.name;
  const customerMobile = printReciptData.customer.mobileNo === "9999999999" ? "" : printReciptData.customer.mobileNo;
  const fullAddress = tillData.tillAccess.csBunit.locations[0].fulladdress.replaceAll(", ", ",<br />");

  // printData = printData.replace("@@csBunitName@@", `${tillData.tillAccess.csBunit.name}`);
  printData = printData.replace("@@csBunitCustomerAddressFulladdress@@", `${fullAddress}`);
  // printData = printData.replace("@@csBunitCustomerAddressCity@@", `${tillData.tillAccess.csBunit.customerAddress.city}`);
  printData = printData.replace("@@csBunitCustomerAddressPhone@@", `${tillData.tillAccess.csBunit.locations[0].phone}`);
  printData = printData.replace("@@receiptType@@", `${receiptType}`);
  printData = printData.replace("@@username@@", `${localStorage.getItem("username")}`);
  printData = printData.replaceAll("@@printReciptDataDocumentno@@", `${printReciptData.documentno}`);
  printData = printData.replace("@@date@@", `${new Date().toLocaleString()}`);
  printData = printData.replace("@@printReciptDataCustomerName@@", `${customerName}`);
  printData = printData.replace("@@printReciptDataCustomerMobileNo@@", `${customerMobile}`);
  printData = printData.replace("@@printReciptDataItems@@", `${printItems}`);
  printData = printData.replace("@@printReciptDataItemsLength@@", `${parseFloat(printReciptData.items.length).toFixed(2)}`);
  printData = printData.replace("@@printReciptDataTotalQty@@", `${parseFloat(printReciptData.totalQty).toFixed(2)}`);
  printData = printData.replace("@@taxAmountBreakup@@", `${taxAmountBreakup}`);
  printData = printData.replace("@@netAmount@@", `${(parseFloat(printReciptData.total) - parseFloat(printReciptData.tax)).toFixed(2)}`);
  printData = printData.replace("@@taxAmount@@", `${parseFloat(printReciptData.tax).toFixed(2)}`);
  printData = printData.replace("@@discountAmount@@", `${parseFloat(printReciptData.discount).toFixed(2)}`);
  printData = printData.replace("@@roundOff@@", `${parseFloat(printReciptData.roundOff).toFixed(2)}`);
  printData = printData.replace("@@total@@", `${parseFloat(printReciptData.total).toFixed(2)}`);
  printData = printData.replace("@@printReciptDataPayments@@", `${printPayments}`);
  printData = printData.replace("@@amountReceived@@", `${parseFloat(printReciptData.paid).toFixed(2)}`);
  printData = printData.replace("@@cashChangeAmount@@", `${parseFloat(printReciptData.change).toFixed(2)}`);


  axios
    .post(`${printerURL}printReceipt`, printData, {
      headers: { "Content-Type": "text/plain" },
    })
    .then(() => {
      console.log("XML print success !");
    })
    .catch(() => {
      console.log("XML print failed !");
    });
};
