import React, { useState } from "react";
import { Modal,Row, Col, Card, Tooltip, Table, Divider, Button, Input, Menu, Spin, Badge, message } from "antd";
import { SearchOutlined, ShoppingCartOutlined, CloseOutlined, ArrowLeftOutlined, LoadingOutlined, CloseSquareFilled, InfoOutlined } from "@ant-design/icons";
import MenuIcon from "../../../assets/images/menu.png";
// import BarcodeReader from "../../../lib/BarCodeComponet";
import POSLogo from "../../../assets/images/cwPosLogo.png";
// import DropdownCircle from "../../../assets/images/DropdownCircle.png";
import DropdownWhite from "../../../assets/images/DropdownCircleWhite.svg";
import CancelOrder from "../../../assets/images/cancel-order.png";
import IncrementIcon from "../../../assets/images/plus.png";
import DecrementIcon from "../../../assets/images/minus.png";
import DeleteCart from "../../../assets/images/Iconly-Light-Delete.svg";
import Invoice from "../../../assets/images/receipt.png";
import Home from "../../../assets/images/dashboard.png";
import onHold from "../../../assets/images/timer.png";
import FilterIcn from "../../../assets/images/op.svg";
import Offers from "../../../assets/images/offers.png";
import SalesRepo from "../../../assets/images/salesRep.svg";
import AddCust from "../../../assets/images/addCust.svg";
import AddProduct from "./AddProduct";
import ProductPanel from "./ProductPanel";
import QuantityUpdate from "./QuantityUpdate";
import Rupees from "../../../assets/images/rupees.png";
import CircleCloseIcon from "../../../assets/images/circle-close-icon.svg";
import RetailModals from "./RetailModals";
import "react-aspect-ratio/aspect-ratio.css";
import "../../style.css";
import "../../globalStyles.css";
import data from "../../../constants/retailPos.json";
// import BarcodeReader from "react-barcode-reader";
// import BarCodeComponent from "../../../lib/BarCodeComponet";
import BarCodeComponentForRetail from "../../../lib/BarCodeComponentForRetail";
import db from "../../../database";

// GLOBAL CONSTANTS START
const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;
// GLOBAL CONSTANTS END

// PointOfSaleRetail Component Start
const PointOfSaleRetail = (props) => {
  const dynamicHeight = 0.5625;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const {
    addProduct,
    cart,
    setCart,
    checkIsManualWeight,
    clearProductSearchResults,
    closeProductPanel,
    confirmDiscardCart,
    decreaseProductQty,
    deleteCart,
    deleteProduct,
    displayClock,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    loader,
    onBarcodeInput,
    openDisplayParkedBillModal,
    openPaymentModal,
    orderType,
    parkBill,
    parkedList,
    productCategories,
    productSearchInput,
    productSearchInputRef,
    productsList,
    selectProductCategory,
    selectProductInCart,
    selectedProductInCart,
    selectedRowKeys,
    setDisplayCustomerSearch,
    setCloseCustomerFlag,
    setDisplayOrderType,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    showOrderHistory,
    tillData,
    openSideMenu,
    currencyType,
    setSalesRepModalOpen,
    salesRepresent,
    setSalesRepresent,
    setSalesRepresentSearchInput,
    setFilteredSalesRepresentList,
    salesReprestiveList,
    setManualDiscountInput,
    setManualDiscountModalVisible,
    clearSelectedProductInCart,
    productListCardRef,
    posLogActivity,
    removeCutomer,
  } = props;

  const currenciesList = tillData.tillAccess.csBunit.currencies;

  const removeProductModal = ()=>{
    if(selectedRowKeys.length > 0) {
    Modal.confirm({
      title: "Do you want to remove the product",
      okText: "Confirm",
      icon: null,
      cancelText: "Cancel",
      onOk() {
          deleteProduct(selectedProductInCart)
      }
    });
  }

  }

  const deleteProductModal = ()=>{
    if(cart.items.length > 0){
      Modal.confirm({
        title: "Do you want to delete the receipt",
        okText: "Confirm",
        icon: null,
        cancelText: "Cancel",
        onOk() {
           deleteCart(true)
        },
      });
    }
   
  }

  return (
    <div className="main" style={data.posScreenStyles.main}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loader}>
        <BarCodeComponentForRetail onScan={onBarcodeInput} />
        {/* <BarCodeComponent minLength={4} onScan={onBarcodeInput} /> */}
        <Row className="header" /* style={{ height: `${3.7 * dynamicHeight}vw` }} */>
          <Col span={2}>
            <img src={MenuIcon} className="posMenuIcon" alt="" onClick={openSideMenu} />
          </Col>
          <Col span={10}>
            <label className="posBunit">{tillData.tillAccess.csBunit.name}</label>
          </Col>
          <Col span={8}>
            <label className="timeFmt">{tillData.tillAccess.cwrTill.till}</label>
          </Col>
          <Col span={2} style={data.posScreenStyles.header.right}>
            <label className="time">{displayClock}</label>
          </Col>
          <Col span={2} style={data.posScreenStyles.header.right}>
            <img onClick={confirmDiscardCart} src={POSLogo} className="posLogoImg" alt="" />
          </Col>
        </Row>
        <Row>

          {/* LEFT SIDE */}
          <Col span={12}>
            <Card bodyStyle={{ padding: 10 }} className="leftCard" style={data.posScreenStyles.leftCard}>
              <Row>
                <Col span={21}>
                  <Row>
                    <Col span={24}>
                      <Card style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }} className="orderTypeSelectionCard" onClick={() => setDisplayOrderType(true)}>
                        <Row>
                          <Col span={21}>
                            <label className="labelB">{orderType ? orderType.cwrSaletype.name : ""}</label>
                          </Col>
                          <Col span={3}>
                            <img className="downIcon" style={data.posScreenStyles.orderCustomerCards.downIcon} src={DropdownWhite} alt="" />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 8 }}>
                    <Col span={8} style={{ cursor: "pointer" }}>
                      <div style={{ paddingTop: 5 }}>
                        <span
                          onClick={(e) => {
                            setDisplayCustomerSearch(true);
                            setCloseCustomerFlag(true)
                          }}
                        >
                          <img className="addCustIcon" src={AddCust} alt="" />{" "}
                          <span className="addCustomerText">{cart.customer.name === "Anonymous Customer" ? "Add Customer" : cart.customer.name}</span>
                        </span>{" "}
                        {cart.customer.name !== "Anonymous Customer" ? <img src={CircleCloseIcon} alt="" width={15} height={15} onClick={removeCutomer} /> : null}
                      </div>
                    </Col>
                    {posConfig.showOrderSalesRep === "Y" ? null : <Col span={8}></Col>}
                    <Col span={8}>
                      <Card
                        className="salesRepCard"
                        bodyStyle={{ padding: "5px 10px" }}
                        onClick={() => {
                          setManualDiscountModalVisible(true);
                          setManualDiscountInput("");
                        }}
                      >
                        <p className="saleRepName">&ensp;Apply Discount</p>
                      </Card>
                    </Col>
                    {posConfig.showOrderSalesRep === "Y" ? (
                      <Col span={8}>
                        <Card
                          style={{ display: posConfig.showOrderSalesRep === "Y" ? "block" : "none" }}
                          className="salesRepCard"
                          bodyStyle={{ padding: "5px 10px" }}
                          onClick={() => {
                            setSalesRepModalOpen({ status: true, title: "orderSalesRep" });
                            setSalesRepresentSearchInput("");
                            setFilteredSalesRepresentList(salesReprestiveList);
                          }}
                        >
                          <p className="saleRepName">
                            {" "}
                            <img className="salesRepoIcon" src={SalesRepo} alt="" /> &ensp; {salesRepresent.name || "Add Sales Rep"}
                            <span style={{ float: "right", display: salesRepresent.name ? "block" : "none" }}>
                              <CloseOutlined
                                className="salesRepClose"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSalesRepresent({});
                                  setCart({ ...cart, salesRepId: null });
                                  if (
                                    db.logConfiguration.toArray().then((fetched) => {
                                      fetched.map((item) => {
                                        item.salesRepDelete.filter((item) => item.log === "Y").length > 0;
                                      });
                                    })
                                  ) {
                                    posLogActivity(salesRepresent.name, "SRD");
                                  }
                                }}
                              />
                            </span>
                          </p>
                        </Card>
                      </Col>
                    ) : null}
                  </Row>
                  <Row style={{ paddingTop: "8px" }}>
                    <Col span={24}>
                      <Card style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 0 }}>
                        <Table
                          className="table-stl"
                          style={data.posScreenStyles.cartTable.tableStl}
                          pagination={false}
                          dataSource={[...cart.items]}
                          sticky
                          scroll={{ y: 278 }}
                          rowClassName={(record) => (selectedRowKeys[0] === record.key ? "tableRowSelectedBlue" : "tableRowNonSelectedWhite")}
                          columns={[
                            {
                              title: "Description",
                              dataIndex: "name",
                              key: "name",
                              width: "45%",
                              render: (text, record, index) => {
                                return (
                                  <>
                                    <div>{record.name}</div>
                                    <div className="offerName">
                                      {cart.discount > 0 ? record.discountName || "" : ""}{" "}
                                      {cart.discount > 0 ? record.discountName ? <img src={Offers} alt="" width={15} height={15} style={{ marginTop: "-2px" }} /> : "" : ""}
                                    </div>
                                  </>
                                );
                              },
                            },
                            {
                              title: "Qty",
                              dataIndex: "weight",
                              key: "weight",
                              render: (text, record, index) => {
                                const wt = parseFloat(text);
                                if (Number.isInteger(wt)) {
                                  return (
                                    <>
                                      <div>{wt}</div>
                                      <div className="offerName">{record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}</div>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <div>{parseFloat(text).toFixed(2)}</div>
                                      <div className="offerName">{record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}</div>
                                    </>
                                  );
                                }
                              },
                            },
                            {
                              title: "Unit Price",
                              dataIndex: "salePrice",
                              key: "salePrice",
                              render: (text) => {
                                return parseFloat(text).toFixed(2);
                              },
                            },
                            {
                              title: "Net Total",
                              dataIndex: "nettotal",
                              key: "nettotal",
                              render: (text) => {
                                return parseFloat(text).toFixed(2);
                              },
                              align: "right",
                            },
                          ]}
                          // rowSelection={{ selectedRowKeys }}
                          onRow={(record) => ({
                            onClick: () => {
                              selectProductInCart(record);
                            },
                          })}
                        />
                        <div onClick={clearSelectedProductInCart} style={data.posScreenStyles.cartTable.prodTotal}>
                          <Divider style={data.posScreenStyles.cartTable.divider} />
                          <Row style={{ padding: 7, backgroundColor: "" }}>
                            <Col span={6}>
                              <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">Tax</label>
                            </Col>
                            <Col span={18} style={data.posScreenStyles.cartTable.amtTextAlign}>
                              <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                              {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                &nbsp;
                                {parseFloat(cart.tax).toFixed(2)}
                                {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                              </label>
                            </Col>
                          </Row>
                          <Row style={{ padding: 7, backgroundColor: "" }}>
                            <Col span={6}>
                              <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">Discounts</label>
                            </Col>
                            <Col span={18} style={data.posScreenStyles.cartTable.amtTextAlign}>
                              <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                              {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                &nbsp;
                                {parseFloat(cart.discount).toFixed(2)}
                                {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                              </label>
                            </Col>
                          </Row>
                          <Row style={{ padding: 7, backgroundColor: "" }}>
                            <Col span={8}>
                              <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">Items / Qty</label>
                            </Col>
                            <Col span={16} style={data.posScreenStyles.cartTable.amtTextAlign}>
                              <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                                {cart.items.length} / {parseFloat(cart.totalQty).toFixed(2)}
                              </label>
                            </Col>
                          </Row>
                          <Row style={{ padding: 7 }}>
                            <Button size="large" className="totalBtn" style={data.posScreenStyles.cartTable.totalBtn} onClick={openPaymentModal}>
                              <Row>
                                <Col span={12} style={data.posScreenStyles.header.left}>
                                  <label style={data.posScreenStyles.cartTable.totalBtn.label} className="totalLabel">Total</label>
                                </Col>
                                <Col span={12} style={data.posScreenStyles.header.right}>
                                  <label style={data.posScreenStyles.cartTable.totalBtn.label} className="totalLabel">
                                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                    &nbsp;
                                    {cart.total !== undefined ? parseFloat(cart.total).toFixed(2) : "0.00"}
                                    {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                  </label>
                                </Col>
                              </Row>
                            </Button>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col span={3}>
                  <Row gutter={[0, 16]}>
                    <Col span={21} offset={3}>
                      <Tooltip title="Quantity">
                        <button
                          className="productSpecChange"
                          style={data.posScreenStyles.productSpecChange}
                          onClick={() => (selectedRowKeys.length > 0 ? setIsQtyUpdate(true) : "")}
                        >
                          <InfoOutlined style={{ fontSize: `${3.5 * dynamicHeight}vw` }} />
                        </button>
                      </Tooltip>
                    </Col>
                    <Col span={21} offset={3}>
                      <Tooltip title="Remove Product">
                        <button
                          className="productSpecChange"
                          style={data.posScreenStyles.productSpecChange}
                          onClick={removeProductModal}
                        >
                          <img src={CancelOrder} alt="remove" className="productSpecImg" />
                        </button>
                      </Tooltip>
                    </Col>
                    <Col span={21} offset={3}>
                      <Tooltip title="Decrease Qty">
                        <button
                          className="productSpecChange"
                          style={data.posScreenStyles.productSpecChange}
                          onClick={() => (selectedRowKeys.length > 0 && parseFloat(selectedProductInCart.weight) > 1 ? decreaseProductQty(selectedProductInCart) : "")}
                        >
                          <img src={DecrementIcon} alt="decreaseqty" className="productSpecImgDec" />
                        </button>
                      </Tooltip>
                    </Col>
                    <Col span={21} offset={3}>
                      <Tooltip title="Increase Qty">
                        <button
                          className="productSpecChange"
                          style={data.posScreenStyles.productSpecChange}
                          onClick={() => (selectedRowKeys.length > 0 ? increaseProductQty(selectedProductInCart) : "")}
                        >
                          <img src={IncrementIcon} alt="increment" className="productSpecImg" />
                        </button>
                      </Tooltip>
                    </Col>
                    <Col span={21} offset={3}>
                      <Tooltip title="Delete Receipt">
                        <button className="productSpecChange" style={data.posScreenStyles.productSpecChange} onClick={deleteProductModal}>
                          <img src={DeleteCart} alt="delete cart" className="productSpecImg" />
                        </button>
                      </Tooltip>
                    </Col>
                    <Col span={21} offset={3}>
                      <Tooltip title="Order History">
                        <button className="productSpecChange" style={data.posScreenStyles.productSpecChange} onClick={() => showOrderHistory()}>
                          <img src={Invoice} alt="invoice" className="productSpecImg" />
                        </button>
                      </Tooltip>
                    </Col>
                    <Col span={21} offset={3}>
                      <Tooltip title="Parked Receipts">
                        <button
                          className="productSpecChange"
                          style={data.posScreenStyles.productSpecChange}
                          onClick={() => (parkedList.length > 0 ? openDisplayParkedBillModal() : "")}
                        >
                          <Badge count={parkedList.length || 0} showZero offset={[0, 24]} style={{ backgroundColor: "#0C173A" }}>
                            <img src={onHold} alt="parkedBill" className="productSpecImg" />
                          </Badge>
                        </button>
                      </Tooltip>
                    </Col>
                    <Col span={21} offset={3}>
                      <Tooltip title="Dashboard">
                        <button className="productSpecChange" style={data.posScreenStyles.productSpecChange} onClick={confirmDiscardCart}>
                          <img src={Home} alt="dashboard" className="productSpecImg" />
                        </button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* RIGHT SIDE */}
          <Col span={12}>
            <Card
              bodyStyle={{
                padding: isProductsFilter ? "10px 0px 10px 10px" : "10px",
              }}
              className="rightCard"
              style={{
                marginLeft: "0.5%",
                borderRadius: "5px",
                backgroundColor: isQtyUpdate ? "#F4F5F6" : isSearchProducts ? "#ffffff" : "#F4F5F6",
              }}
            >
              <Row>
                <Col span={isProductsFilter ? 17 : 24}>
                  {!isQtyUpdate && (
                    <Row>
                      <Col
                        style={isSearchProducts && !isQtyUpdate ? data.posScreenStyles.productsSearchCol : ""}
                        span={!isProductsFilter && isSearchProducts && !isQtyUpdate ? 22 : 24}
                      >
                        <Input
                          ref={productSearchInputRef}
                          size="large"
                          id="productSearchInputId"
                          onChange={(e) => {
                            e.target.value === "" ? clearProductSearchResults() : setProductSearchInput(e.target.value);
                          }}
                          value={productSearchInput}
                          onFocus={() => setIsSearchProducts(true)}
                          prefix={isSearchProducts ? <ArrowLeftOutlined style={data.posScreenStyles.backArrowSearchProducts} className="backArrowSearchProducts" onClick={() => closeProductPanel()} /> : null}
                          suffix={
                            productSearchInput.length > 0 ? (
                              <CloseSquareFilled style={data.posScreenStyles.productSearchSuffix} onClick={() => clearProductSearchResults("")} />
                            ) : (
                              <span style={data.posScreenStyles.productSearchSuffix} />
                            )
                          }
                          onPressEnter={getSearchedProducts}
                          addonAfter={<SearchOutlined onClick={getSearchedProducts} style={data.posScreenStyles.backArrowSearchProducts} className="backArrowSearchProducts" />}
                        />
                      </Col>
                      <Col
                        style={{
                          display: !isProductsFilter && isSearchProducts && !isQtyUpdate ? "block" : "none",
                        }}
                        span={2}
                      >
                        <Button className="productsFilterBtn" style={data.posScreenStyles.productsFilterBtn} onClick={() => setIsProductsFilter(true)}>
                          <img src={FilterIcn} alt="filter" style={data.posScreenStyles.filterIcon} />
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <Row style={{ paddingTop: isQtyUpdate ? 0 : "2%" }}>
                    {isQtyUpdate ? (
                      <QuantityUpdate selectedProductInCart={selectedProductInCart} addProduct={addProduct} setIsQtyUpdate={setIsQtyUpdate} />
                    ) : isSearchProducts ? (
                      <ProductPanel
                        checkIsManualWeight={checkIsManualWeight}
                        setIsSearchProducts={setIsSearchProducts}
                        getMoreProducts={getMoreProducts}
                        productsList={productsList}
                        isProductsFilter={isProductsFilter}
                        getSearchedProducts={getSearchedProducts}
                        productListCardRef={productListCardRef}
                      />
                    ) : (
                      <Card
                        style={isSearchProducts ? data.posScreenStyles.productCards : data.posScreenStyles.addProductsCard}
                        className="addProductsCard"
                        bodyStyle={{ padding: 0 }}
                      >
                        <AddProduct checkIsManualWeight={checkIsManualWeight} openPaymentModal={openPaymentModal} parkBill={parkBill} />
                      </Card>
                    )}
                  </Row>
                </Col>
                <Col style={data.posScreenStyles.productFilterMenu.categoryFiltersCol} span={isProductsFilter ? 7 : 0}>
                  <Menu defaultSelectedKeys={["itemAllProducts"]} defaultOpenKeys={["item01"]} mode="inline">
                    <MenuItem defaultOpenKeys={["item01"]} style={data.posScreenStyles.productFilterMenu.menuItem}>
                      FILTERS
                      <span style={data.posScreenStyles.productFilterMenu.filterCloseIcon}>
                        <CloseOutlined onClick={() => setIsProductsFilter(false)} />
                      </span>
                    </MenuItem>
                    <SubMenu
                      key="item01"
                      icon={<ShoppingCartOutlined style={data.posScreenStyles.productFilterMenu.shoppingCartIcon} />}
                      title={<span style={data.posScreenStyles.productFilterMenu.categoryTitle}>Category</span>}
                    >
                      <MenuItem
                        key={"itemAllProducts"}
                        onClick={() => {
                          selectProductCategory("allProducts");
                        }}
                      >
                        All Products
                      </MenuItem>
                      {productCategories.map((item, index) => (
                        <MenuItem
                          key={`item${index + 1}`}
                          onClick={() => {
                            selectProductCategory(item.mProductCategoryId);
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </SubMenu>
                  </Menu>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
      <RetailModals {...props} />
    </div>
  );
};

export default PointOfSaleRetail;
