import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Input, Button, Modal, message, Empty, Spin, Form } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../constants/config";
import { clock } from "../../utility/clock";
import Axios from "axios";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import DefaultProductImage from "../../assets/images/default-product.webp";
import CircleCloseIcon from "../../assets/images/circle-close-icon.svg";
import db from "../../database";
import data from "../../constants/purchaseGrn.json";
import "../style.css";
import _ from "lodash";

const PriceUpdate = () => {
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const currenciesList = tillData.tillAccess.csBunit.currencies;

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  const handlePosLogo = () => {
    history.push("/dashboard");
  };

  const [confirmModalClose, setConfirmModalClose] = useState(false);

  const handleHome = (e) => {
    if (e.keyCode === 27 && e.keyCode !== 13) {
      if (document.activeElement.className === "ant-modal-wrap") {
        setConfirmModalClose(false);
      } else {
        Modal.confirm({
          content: <div>Go to Home Screen?</div>,
          okText: "Ok",
          onOk: () => {
            history.push("/dashboard");
            setConfirmModalClose(false);
          },
          onCancel: () => {
            setConfirmModalClose(false);
          },
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleHome);
    return () => {
      window.removeEventListener("keydown", handleHome);
    };
  }, []);

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [displayAddProductModal, setDisplayAddProductModal] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [productSearchInput, setProductSearchInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const editorActiveState = useRef(false);

  useEffect(() => {
    db.products
      // .limit(10)
      .toArray()
      .then((productsFetched) => {
        setProductsList([...productsFetched]);
      });
  }, []);

  useEffect(() => {
    if (productSearchInput) {
      db.products
        .where("name")
        .startsWithIgnoreCase(productSearchInput)
        .or("batchIndex")
        .equalsIgnoreCase(productSearchInput)
        .or("upcIndex")
        .equalsIgnoreCase(productSearchInput)
        .or("value")
        .equalsIgnoreCase(productSearchInput)
        .limit(10)
        .toArray()
        .then((productsFetched) => {
          setProductsList([...productsFetched]);
        });
    }
  }, [productSearchInput]);

  const addSelectedProduct = (product) => {
    setDisplayAddProductModal(false);
    setSelectedProducts((existingProducts) => [...existingProducts, product]);
  };

  const updateSelectedProductsValue = (e, i) => {
    const valueField = e.target.value;
    if (valueField) {
      editorActiveState.current = false;
      selectedProducts[i].newPrice = parseFloat(valueField);
    } else {
      editorActiveState.current = true;
      selectedProducts[i].newPrice = selectedProducts[i].sunitprice;
    }
    setSelectedProducts([...selectedProducts]);
  };

  const processProcessPriceUpdate = async () => {
    if (!editorActiveState.current) {
      setLoading(true);
      let productPricingValues = "";
      selectedProducts.forEach((p) => {
        productPricingValues += `{
          mProductId: "${p.mProductId}"
          csBunitId: "${tillData.tillAccess.csBunit.csBunitId}"
          salePrice: ${p.newPrice}
          listPrice: ${p.newPrice}
        }`;
      });

      const priceUpdateResult = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
          upsertProductPrice(
            product: {
              products: [${productPricingValues}]
            }
          ) {
            status
            message
          }
        }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      });
      if (priceUpdateResult.data.data.upsertProductPrice.status === "200") {
        setLoading(false);
        message.success("Prices Updated Sucessfully !");
        setTimeout(() => {
          history.push("/sync");
        }, 500);
      } else {
        setLoading(false);
        message.success("Something went wrong ! Try again");
      }
    } else {
      message.warning("Please fill all inputs with valid values !");
    }
  };

  const removeProductFromSelected = (i) => {
    selectedProducts.splice(i, 1);
    setSelectedProducts([...selectedProducts]);
  };

  // Import Functionality Starts //

  const [form] = Form.useForm();
  const [importModalVisible, setImportModalVisible] = useState(false);

  const getImportModal = () => {
    setImportModalVisible(true);
  };
  const importModalClose = () => {
    setImportModalVisible(false);
    form.resetFields();
    document.getElementById("choosefile").value = null;
  };

  // const [importProductsListFromDB, setImportProductsListFromDB] = useState([]);

  const readFileData = (evt) => {
    setLoading(true);
    const { files } = evt.target;
    const filename = files[0].name;
    const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
    if (fileExt !== ".csv") {
      message.error("Please select csv file");
    } else {
      const file = files[0];
      const reader = new FileReader();
      let fileTextData;
      reader.onload = (e) => {
        fileTextData = e.target.result;
        const rows = fileTextData.split(/\r?\n/);
        var dataArr = [];
        for (var i = 1; i < rows.length - 1; i++) {
          var cells = rows[i].split(",");
          if (cells.length === 2) {
            dataArr.push({
              SKU: cells[0],
              MRP: cells[1],
            });
          }
        }
        if (dataArr.length > 0) {
          const newDataArr = productsList.filter((item) => dataArr.some((record) => item.value === record.SKU));
          newDataArr.map((item, index) => {
            let indexValue = _.findIndex(dataArr, dataItem => dataItem.SKU === item.value);
            if(index !== -1){
              item.modifiedPrice = dataArr[indexValue].MRP
              item.newPrice = dataArr[indexValue].MRP
            }
            return null;
          }) 
          setSelectedProducts((existingProducts) => [...existingProducts, ...newDataArr]);
          setLoading(false);
          setImportModalVisible(false);
          document.getElementById("choosefile").value = "";
        } else {
          message.error("Please import the file in required format.");
          document.getElementById("choosefile").value = null;
        }
      };
      reader.readAsText(file);
    }
  };

  // Import Functionality Ends //

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  return (
    <div>
      {tillaccess.priceUpdate === "Y" ? (
        <Spin indicator={antIcon} spinning={loading}>
          {/* <BarCodeComponent onScan={handleSku} /> */}
          <div style={data.purchaseGrn.header}>
            <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
              <Col span={2}>
                <img src={MenuIcon} style={data.purchaseGrn.header.menuIcon} alt="" />
              </Col>
              <Col span={10}>
                <label style={data.purchaseGrn.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
              </Col>
              <Col span={8}>
                <label style={data.purchaseGrn.header.tillName}>{tillData.tillAccess.cwrTill.till}</label>
              </Col>
              <Col span={2} style={data.purchaseGrn.header.right}>
                <label style={data.purchaseGrn.header.time}>{displayClock}</label>
              </Col>
              <Col span={2} style={data.purchaseGrn.header.right}>
                <img onClick={handlePosLogo} src={POSLogo} style={data.purchaseGrn.header.logoIcon} alt="" />
              </Col>
            </Row>
          </div>
          <div style={{ padding: 10 }}>
            <p style={data.purchaseGrn.purchaseText}>Product Price Update</p>
            <Row style={{ paddingBottom: 10 }}>
              <Col span={4} style={{ alignSelf: "center", paddingLeft: 20 }}>
                <p
                  style={data.purchaseGrn.wastageProdAdd}
                  onClick={() => {
                    setDisplayAddProductModal(true);
                    setProductSearchInput("");
                  }}
                >
                  + ADD Product
                </p>
              </Col>
              <Col span={20} style={{ textAlign: "end" }}>
                <Button onClick={getImportModal} className="importBtn">
                  Import
                </Button>
              </Col>
            </Row>
            <Card
              key="z"
              style={{
                height: "77vh",
                boxShadow: "0px 2px 4px 3px #f0f0f0",
                borderRadius: "8px",
                border: "1px solid #EBEDED",
              }}
              bodyStyle={{ padding: 0 }}
            >
              <Row style={data.purchaseGrn.headingRow}>
                <Col span={12}>
                  <strong>Product</strong>
                </Col>
                <Col span={6} style={{ textAlign: "center" }}>
                  <strong>Previous Price</strong>
                </Col>
                <Col span={6} style={{ textAlign: "center" }}>
                  <strong>New Price</strong>
                </Col>
              </Row>
              {selectedProducts.length > 0 ? (
                <div>
                  <Scrollbars autoHide className="priceUpdateScroll">
                    {selectedProducts.map((item, itemIndex) => (
                      <Row key={`${item.name}-${itemIndex}`} style={{ padding: "10px 10px 0px" }}>
                        <Col span={12}>
                          <Row>
                            <Col span={4}>
                              <img onError={setDefaultImage} src={item.imageurl} alt="" style={data.purchaseGrn.prodImgInTable} />
                            </Col>
                            <Col span={20}>
                              <Row>
                                <p
                                  style={{
                                    marginBottom: 0,
                                    fontSize: "10px",
                                    fontWeight: "normal",
                                    fontFamily: "Poppins",
                                    color: " #607D8B",
                                    letterSpacing: "0.24px",
                                    opacity: 1,
                                  }}
                                >
                                  {item.value}{" "}
                                  <Button onClick={() => removeProductFromSelected(itemIndex)} style={{ fontSize: "10px" }} type="text" danger>
                                    Remove
                                  </Button>
                                </p>
                              </Row>
                              <Row>
                                <p style={data.purchaseGrn.prodTitle}>{item.name}</p>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6} style={{ alignSelf: "center", textAlign: "center" }}>
                          {item.sunitprice}
                        </Col>
                        <Col span={6} style={{ alignSelf: "center", textAlign: "center" }}>
                          <Input type="number" onClick={(e) => e.target.select()} onChange={(e) => updateSelectedProductsValue(e, itemIndex)} defaultValue={item.modifiedPrice ? item.modifiedPrice : item.sunitprice} />
                        </Col>
                      </Row>
                    ))}
                  </Scrollbars>
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: "10% 0px" }} />
              )}
              <div style={{ display: selectedProducts.length > 0 ? "block" : "none" }}>
                <Row style={{ padding: "20px 50px" }}>
                  <Col span={21}>
                    <p style={data.purchaseGrn.totalProducts}>Total Products: {selectedProducts.length}</p>
                  </Col>
                  <Col span={3} style={{ float: "right" }}>
                    <Button style={data.purchaseGrn.receiveBtn} onClick={processProcessPriceUpdate}>
                      <span style={data.purchaseGrn.receiveBtn.label}>CONFIRM</span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>

          {/* ADD PRODUCTS MODAL START */}
          <Modal visible={displayAddProductModal} Cancel={null} closable={false} footer={null} bodyStyle={{ padding: 15 }}>
            <Row>
              <Col span={12}>
                <p style={data.purchaseGrn.addProdToStockModal.modalTitle}>Add Product</p>
              </Col>
              <Col span={12}>
                <img src={CircleCloseIcon} alt="" style={{ float: "right" }} onClick={() => setDisplayAddProductModal(false)} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  size="large"
                  placeholder="Search"
                  value={productSearchInput}
                  onChange={(e) => {
                    setProductSearchInput(e.target.value);
                  }}
                  allowClear
                  suffix={<SearchOutlined />}
                  className="stockProdSearchIpt"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Scrollbars autoHide className="addWastageProdScroll">
                {productsList.map((item, itemIndex) => (
                  <Row key={`${item.name}-${itemIndex}`} style={{ marginBottom: 5, cursor: "pointer" }} onClick={() => addSelectedProduct(item)}>
                    <Col span={4}>
                      <img onError={setDefaultImage} src={item.imageurl} alt="" style={data.purchaseGrn.prodImgInTable} />
                    </Col>
                    <Col span={20} style={{ paddingTop: 5 }}>
                      <Row>
                        <p style={data.purchaseGrn.desc}>
                          <b>Unit Price: </b>
                          {parseFloat(item.sunitprice).toFixed(2)}
                        </p>
                      </Row>
                      <Row>
                        <p style={data.purchaseGrn.prodTitle}>{item.name}</p>
                      </Row>
                    </Col>
                  </Row>
                ))}
              </Scrollbars>
            </Row>
          </Modal>
          {/* ADD PRODUCTS MODAL END */}

          {/* Import Products Modal Starts */}
          <Modal visible={importModalVisible} onCancel={importModalClose} closable={null} centered width="50%" footer={[<Button onClick={importModalClose}>Close</Button>]}>
            <h3 style={{ textAlign: "center" }}>Import Products</h3>
            <Form layout="vertical" form={form} name="importform">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label=" ">
                    <input id="choosefile" type="file" accept=".csv" onChange={readFileData} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          {/* Import Products Modal Ends */}
        </Spin>
      ) : (
        <h1>Access Denied</h1>
      )}
    </div>
  );
};

export default PriceUpdate;
