module.exports = {
  server: "Pre Production",
  serverTokenUrl: "https://s360app.mycw.in/cw-identity/oauth/token",
  serverUrl: "https://s360app.mycw.in/cw-retail/graphql",
  CWCoreServicesUrl: "https://sapp.mycw.in/services/graphql",
  edcUrl: "http://localhost:80/innoweb/api/MSwipe",
  innovitiPaymentCodes: {
    Card: 0,
    GPay: 86,
    Sodexo: 90,
    UPI: 86,
  },
  fileDownloadUrl: 'https://sapp.mycw.in:8443/'
};
