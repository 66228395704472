import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, Input, Button } from "antd";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";

const CloseTill = () => {
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const paymentMethodList = tillData.tillAccess.csBunit.paymentMethodList;
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const sessionId = tillSession.tillSessionId;
  const history = useHistory();

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });
  const [cashExpectedAmount, setCashExpectedAmount] = useState(0);
  const [cashTransactionCount, setCashTransactionCount] = useState(0);
  const [allPaymentsData, setAllPaymentsData] = useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
  const [cashSaleAmount, setCashSaleAmount] = useState(0);

  useEffect(() => {
    db.tillRegistrations.toArray().then((reg) => {
      if (reg.length > 0) {
        const curList = reg[0].tillAccess[0].csBunit.csCurrency;
        setCurrencyType({
          currSymbolLeft: curList.symbolrightside ? "" : curList.currSymbol,
          currSymbolRight: curList.symbolrightside ? curList.currSymbol : "",
          stdPrecision: curList.stdPrecision,
        });
      }
    });

    const newPaymentsList = [];
    db.orders
      .where("tillSessionId")
      .equals(sessionId)
      .toArray()
      .then((orders) => {
        paymentMethodList.forEach((payment) => {
          let currPaymentAmount = 0;
          let transactionCount = 0;
          orders.forEach((order) => {
            const orderPayments = order.payments;
            orderPayments.forEach((orderPayment) => {
              if (payment.name.toLowerCase() === orderPayment.name.toLowerCase()) {
                currPaymentAmount += parseFloat(orderPayment.amount);
                transactionCount += 1;
              }
            });
          });
          payment.expectedAmount = currPaymentAmount;
          payment.amount = 0;
          payment.difference = 0 - currPaymentAmount;
          payment.transactionCount = transactionCount;
          newPaymentsList.push(payment);
        });
        setAllPaymentsData([...newPaymentsList]);
        const cashIndex = newPaymentsList.findIndex((p) => p.name.toLowerCase() === "cash");
        const cashAmount = parseFloat(newPaymentsList[cashIndex].expectedAmount);
        const cashTrxCount = parseFloat(newPaymentsList[cashIndex].transactionCount);
        const openAmount = JSON.parse(localStorage.getItem("tillSession")).totalOpeningAmount;
        setTotalOpeningAmount(openAmount);
        setTotalOrdersCount(orders.length);
        setCashSaleAmount(cashAmount);
        const expAmt = tillData.tillAccess.csBunit.incDayOpening === "N" ? parseFloat(cashAmount) : parseFloat(cashAmount) + parseFloat(openAmount);
        setCashExpectedAmount(expAmt);
        setCashTransactionCount(cashTrxCount);
      });
  }, []);


  const [denominations, setDenominations] = useState([]);

  useEffect(() => {
    const modifiedCurrenciesList = currenciesList[0].denominations?.map((denomItem, denomIndex) => ({
      ...denomItem,
      count : 0,
      amount: 0
    }))
    
    setDenominations([...modifiedCurrenciesList]);
  }, [])

  // const [denominations, setDenominations] = useState([
  //   {
  //     denomination: 1,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 2,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 5,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 10,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 20,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 50,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 100,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 200,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 500,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 2000,
  //     count: 0,
  //     amount: 0,
  //   },
  // ]);

  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const [totalAmount, setTotalAmount] = useState(0);

  const onFieldInput = (e) => {
    const { name, value } = e.target;
    const denominationName = parseInt(name);
    const denominationValue = parseInt(value);
    const denominationAmount = denominationName * denominationValue;
    const di = denominations.findIndex((d) => d.value === denominationName);
    denominations[di].count = isNaN(denominationValue) ? "" : denominationValue;
    denominations[di].amount = isNaN(denominationAmount) ? 0 : denominationAmount;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const di = denominations.findIndex((d) => d.value === parseInt(e.target.name));
      denominations[di].count = 0;
      setDenominations([...denominations]);
    }
  };

  const onFieldButton = (field, action) => {
    if (action === "plus") denominations[field].count += 1;
    if (action === "minus" && denominations[field].count > 0) denominations[field].count -= 1;
    const denominationAmount = denominations[field].value * denominations[field].count;
    const denominationAmountValue = isNaN(denominationAmount) ? 0 : denominationAmount;
    denominations[field].amount = denominationAmountValue;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const excuteCloseTill = () => {
    confirmBtnRef.current.disabled = true;
    cancelBtnRef.current.disabled = true;
    const updatedTillSession = tillSession;
    const updatedAllPaymentsData = allPaymentsData;
    const aPi = updatedAllPaymentsData.findIndex((apd) => apd.name.toLowerCase() === "cash");
    updatedAllPaymentsData[aPi].expectedAmount = tillData.tillAccess.csBunit.incDayOpening === "N" ? parseFloat(updatedAllPaymentsData[aPi].expectedAmount) : parseFloat(updatedAllPaymentsData[aPi].expectedAmount) + parseFloat(totalOpeningAmount);
    updatedAllPaymentsData[aPi].amount = totalAmount;
    updatedAllPaymentsData[aPi].cashSaleAmount = cashSaleAmount;
    updatedAllPaymentsData[aPi].difference = parseFloat(totalAmount) - parseFloat(updatedAllPaymentsData[aPi].expectedAmount);
    updatedTillSession.closingTime = timeStamp();
    updatedTillSession.closingCash = totalAmount;
    updatedTillSession.closingCashDenominations = denominations;
    updatedTillSession.allPaymentsData = updatedAllPaymentsData;
    updatedTillSession.totalOrdersCount = totalOrdersCount;
    localStorage.setItem("tillSession", JSON.stringify(updatedTillSession));
    confirmBtnRef.current.disabled = false;
    cancelBtnRef.current.disabled = false;
    history.push("/close-till-all");
  };

  const handleHome = () => {
    history.push("/dashboard");
  }

  return (
    <div style={data.openTill.mainContainer}>
      <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
        <Col span={2}>
          <img src={MenuIcon} style={data.openTill.header.menuIcon} alt="" />
        </Col>
        <Col span={10}>
          <label style={data.openTill.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
        </Col>
        <Col span={8}>
          <label style={data.openTill.header.timeFmt}>CLOSE TILL</label>
        </Col>
        <Col span={4} style={data.openTill.header.right}>
          <img src={POSLogo} style={data.openTill.header.posLogoIcon} alt="" onClick={handleHome}/>
        </Col>
      </Row>
      <div style={data.openTill.mainCardContainer}>
        <Row gutter={10}>
          <Col span={18}>
            <Card bodyStyle={{ padding: 10 }} style={{ borderRadius: "3px" }}>
              <Card key="z" style={data.openTill.mainCardFirst} bodyStyle={{ padding: 0 }}>
                <Row style={{ background: "#EBEDED", padding: "1% 0%" }}>
                  <Col span={8} style={data.openTill.colAlign}>
                    <strong>Denomination</strong>
                  </Col>
                  <Col span={8} style={data.openTill.colAlign}>
                    <strong>Count</strong>
                  </Col>
                  <Col span={8} style={data.openTill.colAlign}>
                    <strong>Amount</strong>
                  </Col>
                </Row>
                <Row style={{ padding: 10 }}>
                  <Scrollbars autoHide className="tillScroll">
                    {denominations.map((denom, index) => (
                      <Row style={{ marginBottom: "2%" }}>
                        <Col span={8} style={data.openTill.colAlign}>
                          <Input readOnly={true} style={data.openTill.currency} value={denom.value} />
                        </Col>
                        <Col span={8} style={data.openTill.colAlign}>
                          <div style={data.openTill.calInputDiv}>
                            <MinusOutlined style={data.openTill.decInpt} type="minus" onClick={() => onFieldButton(index, "minus")} />
                            <Form.Item style={data.openTill.countFormitem}>
                              <Input name={`${denom.value}`} value={denom.count} style={data.openTill.calInput} onChange={onFieldInput} onBlur={onFieldOut} />
                            </Form.Item>
                            <PlusOutlined style={data.openTill.incInpt} type="plus" onClick={() => onFieldButton(index, "plus")} />
                          </div>
                        </Col>
                        <Col span={8} style={data.openTill.colAlign}>
                          <Input style={data.openTill.totalInput} readOnly={true} value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${denom.amount} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`} />
                        </Col>
                      </Row>
                    ))}
                  </Scrollbars>
                </Row>
              </Card>
            </Card>
          </Col>
          <Col span={6}>
            <Card bodyStyle={{ padding: 10 }} style={data.openTill.opentillRightCard}>
              <Link to="/dashboard">
                <Button ref={cancelBtnRef} style={data.openTill.btnCancel}>
                  Back
                </Button>
              </Link>
              <Button ref={confirmBtnRef} style={data.openTill.btnConfirm} onClick={excuteCloseTill}>
                Next
              </Button>
              <p style={data.openTill.emptyP} />
              <p style={data.openTill.emptyP}>Opened On</p>
              <div style={{ marginBottom: "0.5rem" }}>
                <span style={data.openTill.currentDate}>{currentDate}</span>
                <span style={data.openTill.displayClock}>{displayClock}</span>
              </div>
              <Form>
                <Form.Item style={{ marginBottom: "0.5rem" }}>
                  <p style={data.openTill.transactions}>Transactions</p>
                </Form.Item>
                <Form.Item style={{ marginBottom: "1rem" }}>
                  <p style={data.openTill.transactionsAmt}>Opening Amount</p>
                  <Input
                    value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalOpeningAmount).toFixed(currencyType.stdPrecision)} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                    readOnly={true}
                    className="transactionAmtInput"
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: "1rem" }}>
                  <p style={data.openTill.transactionsAmt}>Cash Sale Amount</p>
                  <Input
                    value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashSaleAmount).toFixed(currencyType.stdPrecision)} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                    readOnly={true}
                    className="transactionAmtInput"
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: "1rem" }}>
                  <p style={data.openTill.transactionsAmt}>Expected Cash</p>
                  <Input
                    value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashExpectedAmount).toFixed(currencyType.stdPrecision)} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                    readOnly={true}
                    className="transactionAmtInput"
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: "1rem" }}>
                  <p style={data.openTill.transactionsAmt}>Actual Cash</p>
                  <Input
                    value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                    readOnly={true}
                    className="transactionAmtInput"
                  />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CloseTill;
