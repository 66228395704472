import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Form, Input, Button, Spin, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { serverTokenUrl, serverUrl } from "../../constants/config";
import { timeStamp } from "../../utility/timestamp";
import { useAuth } from "../../lib/auth";
import Axios from "axios";
import db from "../../database";
import loginBackground from "../../assets/images/backgroundImage.png";
import logoIcon from "../../assets/images/cwpos-blue.png";
import data from "../../constants/registrationLogin.json";
import "antd/dist/antd.css";
import "../style.css";

const TillRegistration = () => {
  const [loading, setLoading] = useState({ status: false, message: "" });
  const history = useHistory();
  const { setAuthTokens } = useAuth();
  const tillIdInputRef = useRef();

  useEffect(() => {
    (async () => {
      const tillRegistrations = await db.tillRegistrations.toArray();
      if (tillRegistrations.length > 0) {
        history.push("/");
      } else {
        setTimeout(() => {
          tillIdInputRef.current.focus();
        }, 100);
      }
    })();
  }, []);

  const handleKey = (e) => {
    const { keyCode } = e;
    if(keyCode >= 38 && keyCode <= 40){
      setTimeout(() => {
        tillIdInputRef.current.focus();
      }, 100);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const onRegister = async (values) => {
    setLoading({ status: true, message: "" });

    const username = values.username.trim();
    const password = values.password.trim();
    const tillId = values.tillId.trim();

    const requestBody = decodeURIComponent(
      new URLSearchParams({
        grant_type: "password",
        username,
        password,
      }).toString()
    );
    let resTokenData;

    try {
      resTokenData = await Axios.post(serverTokenUrl, requestBody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
        },
      });
      setAuthTokens(resTokenData.data);
    } catch (error) {
      setLoading({ status: false, message: "" });
      console.error(error);
      errorMessage(resTokenData.data.error, resTokenData.data.error_description);
      return null;
    }

    try {
      const timeMark = timeStamp();
      const resData = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
          tillRegistration(
            till: {
              searchKey: "${tillId}"
              created: "${timeMark}"
              updated: "${timeMark}"
              tillAccess: { csUser: { username: "${username}" } }
              tillHistory: {
                domain: null
                sessionid: null
                osVersion: null
                localIpAddress: null
                macAddress: null
                processor: null
                ram: null
                regTimestamp: "${timeMark}"
              }
            }
          ) {
            status
            message
            searchKey
            tillAccess {
              salesRep
              salesRepId
              csClientId
              csUser {
                csUserId
              }
              cwrTill {
                till
                searchKey
                description
                nextAssignedNumber
              }
              csBunit {
                csBunitId
                name
                cwrSCustomerId
                csCurrency {
                  csCurrencyId
                  currSymbol
                  isoCode
                  stdPrecision
                  cstgPrecision
                  prcPrecision
                }
              }
            }
            tillHistory {
              domain
              sessionid
              osVersion
              localIpAddress
              macAddress
              processor
              ram
              regTimestamp
            }
          }
        }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${resTokenData.data.token_type} ${resTokenData.data.access_token}`,
        },
      });

      const tillData = resData.data.data.tillRegistration;
      if (tillData.status === "200") {
        try {
          await db.tillRegistrations.add(tillData);
          localStorage.setItem("documentSequence", tillData.tillAccess[0].cwrTill.nextAssignedNumber);
          setLoading({ status: false, message: "" });
          history.push("/");
        } catch (error) {
          console.error(error);
          setLoading({ status: false, message: "" });
          errorMessage("Till Registration Failed", "Unexpected Error");
        }
      } else {
        setLoading({ status: false, message: "" });
        errorMessage(tillData.message);
        console.error(tillData);
      }
    } catch (error) {
      setLoading({ status: false, message: "" });
      errorMessage("Error", "Try Again");
      console.error(error);
    }
  };

  const errorMessage = (modalTitle, modalContent) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
    });
  };

  const antIcon = <LoadingOutlined style={{ fontSize: data.loginStyles.fontSize }} spin />;

  const sectionStyle = {
    width: data.loginStyles.sectionStyle.width,
    height: data.loginStyles.sectionStyle.height,
    paddingTop: data.loginStyles.sectionStyle.paddingTop,
    backgroundImage: `url(${loginBackground})`,
    backgroundRepeat: data.loginStyles.sectionStyle.backgroundRepeat,
    backgroundSize: data.loginStyles.sectionStyle.backgroundRepeat,
  };

  return (
    <div className="aspectRatio">
      <div style={sectionStyle}>
        <Card style={data.loginStyles.loginCard}>
          <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
          <div style={data.loginStyles.loginLogoDiv}>
              <img style={data.loginStyles.loginLogo} src={logoIcon} alt="" />
            </div>
            <Form onFinish={onRegister}>
              <Form.Item name="tillId" rules={[{ required: true, message: "Please input your Till ID" }]}>
                <Row>
                  <Col span={8}>
                    <label style={data.loginStyles.loginLabel}>Till ID</label>
                  </Col>
                  <Col span={16}>
                    <Input ref={tillIdInputRef} style={data.loginStyles.loginInput} placeholder="Till ID" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="username" rules={[{ required: true, message: "Please input your Username" }]}>
                <Row gutter="5">
                  <Col span={8}>
                    <label>Username</label>
                  </Col>
                  <Col span={16}>
                    <Input style={data.loginStyles.loginInput} placeholder="Username" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: "Please input your Password" }]}>
                <Row gutter="5">
                  <Col span={8}>
                    <label>Password</label>
                  </Col>
                  <Col span={16}>
                    <Input.Password style={data.loginStyles.loginInput} placeholder="Password" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <br />
                <Row gutter={[5, 0]}>
                  <Col span={12} />
                  <Col span={12}>
                    <Button size="large" style={data.loginStyles.btn} htmlType="submit">
                      Register
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <br />
        <div style={data.loginStyles.copyrightMainDiv}>
          <label style={data.loginStyles.copyright}>&emsp;&emsp;Copyright © {new Date().getFullYear()} CW Solutions Pvt Ltd | All rights reserved</label>
          <br />
          <label style={data.loginStyles.copyright}>&emsp;&emsp;www.cw.solutions</label>
        </div>
      </div>
    </div>
  );
};

export default TillRegistration;
