import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { AuthContext } from "../lib/auth";
import PrivateRoute from "./PrivateRoute";
import Login from "./Login";
import TillReg from "./TillRegistration";
import PointOfSale from "./PointOfSale";
import Dashboard from "./Dashboard";
import Sync from "./Sync";
import OpenTill from "./OpenTill";
import OpenTill1 from "./OpenTill/OpenTill1";
import CloseTill from "./CloseTill";
import CloseTillAll from "./CloseTill/CloseTIllAll";
import TableManagement from "./PointOfSale/Restaurant/Tables-Management/tables";
import BillManagement from "./PointOfSale/Restaurant/billManagement";
import DayClose from "./DayClose";
import PurchaseGRN from "./PurchaseGrn";
import STReceipt from "./STReceipt";
import WastageQty from "./wastageQty";
import B2BSales from "./B2BSales";
import PriceUpdate from "./PriceUpdate";
import "./style.css";
import serviceWorker from "../serviceWorker";
import { socket } from "../socket";
import { serverUrl } from "../constants/config";
import axios from "axios";

const App = (props) => {

  useEffect(() => {
    const handleOrderReceived = async(order) => {
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const printerURL = tillData?.tillAccess?.cwrTill?.hardwareController?.imageUrl;
      if(tillData?.tillAccess?.salesRep === "N" && order.KOTPrint !== undefined){
       await getFBOrder(order,printerURL);
      }

      if(tillData.tillAccess?.salesRep === "N" && order.CancelKOTPrint !== undefined){
        axios
        .post(`${printerURL}printer`, order.CancelKOTPrint.toString(), {
          headers: { "Content-Type": "application/xml; charset=UTF-8" },
        })
        .then(() => {
          console.log("XML print success !");
        })
        .catch((response) => {
          console.log("XML print failed !",response);
        });
      }
    };

    socket.on('orderReceived', handleOrderReceived);

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('orderReceived', handleOrderReceived);
    };
  }, []);

  const [authTokens, setAuthTokens] = useState(localStorage.getItem("tokens"));
  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  };
  serviceWorker();

  const getFBOrder = async(order,printerURL) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    await axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {
            getFBOrder(tableId:null,
            status: "IP") {
            cSBunitID
            cSClientID
            isactive
            fbOrderId
            fbTableId
            fbSectionId
            sOrderId
            orderNo
            guestName
            guestType
            referredBy
            date1
            status
            salesRepId
            guests
            isCanceled
            lines{
            isCanceled
            cSBunitID
            cSClientID
            isactive
            fbOrderId
            fbOrderLineId
            mPoductId
            mBatchId
            description
            csUomId
            csTaxId
            line
            qty
            unitPrice
            listPrice
            lineNet
            lineTax
            lineGross
            sOrderLineId
            isOrdered
            meta{
              key
              value
              fbOrderLineMetaId
              }
            }
            }
        }`,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((res) => {
      if(res?.data?.data?.getFBOrder.length>0){
        res.data.data.getFBOrder.map(async(ele)=> {
          if(ele.fbOrderId === order.KOTPrint.fbOrderId){
            axios
            .post(`${printerURL}printer`, order.KOTPrint.print.toString(), {
              headers: {
                'Content-Type': 'application/xml; charset=UTF-8',
                'Accept': 'application/xml'
              }
            })
            .then(() => {
              console.log("XML print success !");
            })
            .catch((response) => {
              console.log("XML print failed !",response);
            }); 
          }
        })
      }
    })
  }

  return (
    <div className="aspectRatio">
      <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
        <Router>
          <Route exact path="/" component={Login}/>
          <Route exact path="/registration" component={TillReg} />
          <PrivateRoute exact path="/pos" component={PointOfSale} />
          <PrivateRoute exact path="/sync" component={Sync} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/open-till" component={OpenTill} />
          <PrivateRoute exact path="/open-till1" component={OpenTill1} />
          <PrivateRoute exact path="/close-till" component={CloseTill} />
          <PrivateRoute exact path="/close-till-all" component={CloseTillAll} />
          {/* <PrivateRoute exact path="/cash-event" component={CashEvent} /> */}
          <PrivateRoute exact path="/table-management" component={TableManagement} />
          {/* <PrivateRoute exact path="/bill-management" component={BillManagement} /> */}
          <PrivateRoute exact path="/day-close" component={DayClose} />
          <PrivateRoute exact path="/purchase-grn" component={PurchaseGRN} />
          <PrivateRoute exact path="/stock-receipt" component={STReceipt} />
          <PrivateRoute exact path="/wastage-qty" component={WastageQty} />
          <PrivateRoute exact path="/b2b-sales" component={B2BSales} />
          <PrivateRoute exact path="/price-update" component={PriceUpdate} />
        </Router>
      </AuthContext.Provider>
    </div>
  );
};

export default App;